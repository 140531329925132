<template>
  <div id="defaultModal" tabindex="1" aria-hidden="true"
    class="backdrop-blur-sm mx-auto z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative mx-auto w-full max-w-2xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
          </h3>
          <button type="button" @click="$emit('close', false)"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="defaultModal">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6 space-y-6 w-full">
          <a href="#" class="group relative block h-full overflow-hidden rounded-lg w-full lg:mb-3">
            <img :src="product.item.image" class="transition duration-200 group-hover:scale-110 h-48 mx-auto" />
            <div v-if="product.item.stickers" class="grid gap-1 grid-cols-4 mx-auto max-w-sm">
              <!-- product - start -->
              <div v-for="sticker in product.item.stickers" :key="sticker">
                <div class="group relative mx-auto flex h-14 w-14 items-end overflow-hidden rounded-lg p-1 shadow-lg">
                  <img :src="sticker.url" :alt="sticker.name"
                    class="mx-auto h-full w-full object-cover object-center transition duration-200 group-hover:scale-105" />

                </div>
              </div>
              <!-- product - end -->
            </div>
          </a>

          <div class="block w-full p-6 bg-gray-800 rounded-lg shadow">
            <h5 class="mb-2 sm:text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">{{
              product.item.market_hash_name }}</h5>
            <div v-if="product.item.float_value && product.item.float_value > 0" class="mb-3 mt-5">
              <div class="w-full mt-2 text-white max-w-sm mx-auto">
                <div :style="'margin-left: ' + product.item.float_value * 100 + '%;'">
                {{
              product.item.float_value }}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </div>
              </div>
              <div class="w-full h-1 max-w-sm mx-auto"
                style="background-image: linear-gradient(to right, #72f04f, #a2f04f,#fce728, #fce728, #f5253d);"></div>
            </div>
            <div class="flex gap-2.5 h-14 mt-10 place-content-end items-center mx-auto">
              <a :href="product.item.inspect_link" target="_blank"
                class="bg-gray-600 hover:bg-gray-700 focus-visible:ring text-gray-400 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-4 py-3 h-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6 mt-1">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>

              </a>
              <button @click="$emit('buyItem', false)"
                class="bg-green-600 hover:bg-green-500 focus-visible:ring text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-4 py-3 h-full">
                Comprar {{ product.item.price.toLocaleString("pt-BR", { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' }) }}
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TheModal",
  props: {
    product: {
      type: Object
    }
  },
  emits: ['close', 'buyItem']
};
</script>
