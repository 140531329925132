<template>
  <!-- review - start -->
  <div
    class="flex flex-col gap-3 py-4 md:py-8 border border-gray-800 px-4 rounded-lg mb-3 text-white"
  >
    <div>
      <span class="block text-gray-300 text-sm">{{ question.created_at }}</span>
    </div>
    <!-- stars - end -->

    <p class="text-gray-200">
      <span class="font-bold">Pergunta:</span> {{ question.question }}
    </p>

    <template v-if="question.response">
      <div>
        <span class="block text-gray-300 text-sm">{{
          question.response_at
        }}</span>
      </div>
      <p class="text-gray-200" v-if="question.response">
        <span class="font-bold"> Resposta:</span> {{ question.response }}
      </p>
    </template>
    <slot></slot>
  </div>
  <!-- review - end -->
</template>

<script>
export default {
  name: "QuestionComponent",
  data() {
    return {
      announcementName: null,
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  }
};
</script>
