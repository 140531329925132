<template>
  <FormLayout welcomeMessage="Bem vindo a Cguro!">
    <BaseForm :handleSubmit="submitForm" action="Cadastrar-se" />
  </FormLayout>
</template>

<script>
import BaseForm from "@/components/form/BaseForm.vue";
import FormLayout from "@/components/form/FormLayout.vue";
import http from "@/utils/http";
import { managerStore } from "@/stores/manager";
export default {
  name: "RegisterPage",
  methods: {
    async submitForm(data) {
      try {
        await http.post("/users", {
          ...data,
        });
        this.$router.push({ path: "/login" });
        managerStore.commit("toggleNotify", {
          sucess: true,
          errorMessage: "Sucesso ao criar conta!",
        });
      } catch (error) {
        await managerStore.commit("toggleNotify", {
          sucess: false,
          errorMessage: "Conta já cadastrada!",
        });
      }
    },
  },
  components: { BaseForm, FormLayout },
};
</script>
