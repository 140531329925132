<template>
  <div
    class="backdrop-blur-sm mx-auto z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative mx-auto w-full max-w-2xl max-h-full">
      <!-- Modal content -->
      <div class="relative rounded-lg shadow bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-start justify-between p-4 border-b rounded-t border-gray-600">
          <h3 class="text-xl font-semibold text-gray-900 text-white">
          </h3>
          <button type="button" @click="$emit('close', false)"
            class="text-gray-400 bg-transparent hover:bg-gray-300 hover:text-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
            data-modal-hide="defaultModal">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <Transition>
          <div v-if="loading == 0" class="p-5">
            <div
              class="flex items-center m-10 animate-pulse text-cyan-600 justify-center w-full h-64 mx-auto rounded-lg sm:w-96">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-12 h-12">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
              </svg>

            </div>
          </div>
          <div v-else-if="loading == 2" class="p-6 space-y-6 w-full">
            <div v-if="!error" class="w-full">
              <div class="hidden sm:block">
              <div class="block mx-auto mb-5 max-w-lg p-6 bg-gray-800 rounded-lg shadow">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Autenticação via WhatsApp
                </h5>
                <p class="font-normal text-gray-300">Para continuar a compra leia o QR Code pelo celular ou abra o web WhatsApp e envie o código do item.</p>
              </div>
              <vue-qrcode class="mx-auto"
                :value="'https://api.whatsapp.com/send/?phone=551151969011&text=C%C3%B3digo:%20' + item"
                :options="{ width: 300 }"></vue-qrcode>
              <div class="flex place-content-center mb-5 mt-5">
                <a target="_blank" :href="'https://api.whatsapp.com/send/?phone=551151969011&text=C%C3%B3digo:%20' + item" type="button"
                  class="text-white bg-cyan-600 hover:bg-cyan-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5">Enviar
                  via web WhatsApp</a>
              </div>
              </div>
              <div class="sm:hidden">
                <div class="block mx-auto mb-5 max-w-lg p-6 bg-gray-800 rounded-lg shadow">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Autenticação via WhatsApp
                </h5>
                <p class="font-normal text-gray-300">Para continuar a compra clique no botão e envie o código do item.</p>
              </div>
              <div class="animate-pulse w-9 h-9 mx-auto m-5" style="font-size:35px">👇</div>
              <div class="flex place-content-center mb-5">
                <a target="_blank" :href="'https://api.whatsapp.com/send/?phone=551151969011&text=C%C3%B3digo:%20' + item" type="button"
                  class="text-white bg-cyan-600 hover:bg-cyan-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5">Enviar código via WhatsApp</a>
              </div>                
              </div>
            </div>
            <div v-else>
              <span class="mt-5 text-red-500 text-lg text-center">{{ error }}</span>
            </div>
          </div>
        </Transition>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "TheModal",
  data() {
    return {
      whatsapp: null,
      value: "",
      loading: 0,
      error: null,
    }
  },
  props: {
    item: {
      type: String
    }
  },
  mounted() {
    this.axios({
      method: 'post',
      url: "/buyitem",
      data: {
        item_id: this.item // This is the body part
      }
    })
      .then((response) => {

        this.loading = 1
        if (response.status == 200) {
          setTimeout(() => {
            this.loading = 2
          }, 1000);

        } else {
          setTimeout(() => {
            this.loading = 2
          }, 1000);
          return this.error = 'Houve algum problema, tente mais tarde.'
        }
      })
      .catch((err) => {
        this.loading = 1
        setTimeout(() => {
            this.loading = 2
            if (err.response.status == 400) {
              return this.error = err.response.data.error
            }
          }, 1000);
      })
  },
  emits: ['close']
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}</style>
