<template>
  <div
    class="grid grid-cols-1 lg:grid-cols-3 lg:gap-14 w-screen h-screen text-white"
  >
    <aside class="w-full h-full hidden lg:block">
      <img
        src="@/assets/imagem.png"
        alt="Login image"
        class="w-full h-full object-cover"
      />
    </aside>
    <router-link
        to="/"
        class="block m-6 lg:absolute lg:top-10 lg:left-10 w-fit h-fit cursor-pointer items-center p-2 bg-gray-700/60 rounded hover:bg-gray-600/60 transition-colors"
      >
        <Logo />
    </router-link>
    <div class="col-span-2 w-5/6 lg:w-4/6 pb-10 mx-auto lg:m-auto">
      <h2 class="font-bold text-4xl">{{ welcomeMessage }}</h2>
      <slot></slot>
      <p class="mt-5" v-if="this.$route.name !== 'cadastro'">
        Ainda não tem conta?
        <router-link to="/cadastro" class="text-cyan-500">
          Cadastre-se
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
export default {
  name: "FormLayout",
  components: { Logo },
  props: {
    welcomeMessage: {
      type: String,
      required: true,
    },
  },
};
</script>
