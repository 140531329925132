<template>
  <FormLayout welcomeMessage="Bem vindo novamente!">
    <BaseForm :handleSubmit="submitForm" action="Login" />
  </FormLayout>
</template>

<script>
import BaseForm from "@/components/form/BaseForm.vue";
import FormLayout from "@/components/form/FormLayout.vue";
import http from "@/utils/http";
import { userStore } from "@/stores/user";
import { managerStore } from "@/stores/manager";
export default {
  name: "LoginPage",
  methods: {
    async submitForm(data) {
      try {
        const response = await http.post("/login", {
          ...data,
        });
        userStore.commit("login", {
          id: response.data._id,
          name: response.data.name,
          token: response.data.token,
        });
        this.$router.push({ path: "/dashboard" });
      } catch (error) {
        managerStore.commit("toggleNotify", {
          sucess: false,
          errorMessage: "Credenciais incorretas!",
        });
      }
    },
  },
  components: { BaseForm, FormLayout },
};
</script>
