<template>
  <div class="bg-gray-900 pb-6 sm:pb-8 lg:pb-12">
    <div class="max-w-screen-xl px-4 md:px-8 mx-auto">
      <section class="flex flex-col lg:flex-row justify-between gap-6 sm:gap-10 md:gap-16">
        <!-- content - start -->
        <div class="xl:w-5/12 flex flex-col justify-between">
          <div></div>

          <div class="sm:text-center lg:text-left lg:py-12 xl:py-24">
            <p class="text-cyan-300 md:text-lg xl:text-xl font-semibold mb-4 md:mb-6">
              Entrega rápida e segura
            </p>

            <h1 class="text-gray-200 text-4xl sm:text-4xl md:text-5xl mb-8 md:mb-12">
              Compre skins com os melhores preços
            </h1>

            <div class="flex flex-col sm:flex-row sm:justify-center lg:justify-start gap-2.5">
              <a href="/market/todas" 
                class="inline-block bg-red-500 hover:bg-red-400 active:bg-red-400 focus-visible:ring ring-red-300 text-white md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3 cursor-pointer">Visualizar
                skins</a>
            </div>
          </div>

        </div>
        <!-- content - end -->

        <!-- image - start -->
        <div class="mb-12 flex w-full md:mb-16 lg:w-2/3">
          <div
            class="relative top-12 left-12 z-10 -ml-12 overflow-hidden rounded-lg bg-gradient-to-t from-cyan-500 via-cyan-400  to-green-300 w-full h-full shadow-lg md:top-16 md:left-16 lg:ml-0">
            <img src="@/assets/skinone.png" loading="lazy"
              class="h-48 lg:h-64 mt-20 mx-auto object-cover object-center" />
          </div>

          <div
            class="overflow-hidden w-full h-full rounded-lg bg-gradient-to-t from-green-500 via-green-400 to-cyan-300 shadow-lg">
            <img src="@/assets/skintwo.png" loading="lazy"
              class="h-48 lg:h-64 mt-20 mx-auto object-cover object-center" />
          </div>
        </div>
        <!-- image - end -->
      </section>
    </div>
    <div class="py-6 sm:py-8 mt-24">
      <div class="max-w-screen-xl px-4 md:px-8 mx-auto">
        <p class="text-cyan-500 lg:text-lg font-semibold text-center mb-2 md:mb-3">
          Entenda mais sobre nós
        </p>

        <h2 class="text-gray-200 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">
          Nascimento e propósito
        </h2>

        <p class="max-w-screen-md text-gray-400 md:text-lg text-center mx-auto">
          Cansados de ver tantas pessoas sendo enganadas em negóciações
          virtuais, resolvemos desenvolver a Cguro, uma plataforma de
          intermédiação rápida e fácil, para de uma vez por todas solucionar
          esse grande problema. Vamos juntos acabar de uma vez por todas as
          chances dos golpistas se aproveitarem da boa fé? Indique nossa
          plataforma, isso nos ajuda e muito!
        </p>
      </div>
    </div>
  </div>
  <div class="py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-xl px-4 md:px-8">
    <div class="flex flex-col overflow-hidden rounded-lg bg-gray-800 sm:flex-row md:h-80">
      <!-- content - start -->
      <div class="flex w-full flex-col p-4 sm:w-1/2 sm:p-8 lg:w-2/5">
        <h2 class="mb-4 text-xl font-bold text-white md:text-2xl lg:text-4xl">Descontos em skins</h2>

        <p class="mb-8 max-w-md text-gray-400">Aproveite as melhores ofertas da internet e compre sua skin!</p>

        <div class="mt-auto">
          <a href="/market/todas" class="cursor-pointer inline-block rounded-lg bg-gray-600 hover:bg-gray-500 px-8 py-3 text-center text-sm font-semibold text-gray-200 outline-none ring-indigo-300 transition duration-100 focus-visible:ring md:text-base">Marketplace</a>
        </div>
      </div>
      <!-- content - end -->

      <!-- image - start -->
      <div class="order-first h-48 w-full bg-gray-700 sm:order-none sm:h-auto sm:w-1/2 lg:w-3/5">
        <img src="https://s2.glbimg.com/4pSsZRA_b51M9wQcI9RjDoRyYj4=/0x0:1920x1080/984x0/smart/filters:strip_icc()/i.s3.glbimg.com/v1/AUTH_bc8228b6673f488aa253bbcb03c80ec5/internal_photos/bs/2023/3/y/FBesEEQm2EBrBAW9HJDA/dust2-1-aquarius.jpeg" loading="lazy" alt="Photo by Dom Hill" class="h-full w-full object-cover object-center" />
      </div>
      <!-- image - end -->
    </div>
  </div>
</div>
  <div class="pt-4 ">
    <footer class="max-w-screen-2xl px-4 md:px-8 mx-auto">
      <div class="flex flex-col items-center border-t border-gray-800 pt-6">
        <!-- nav - start -->

        <div class="flex gap-4">
          <a href="https://discord.gg/pfa4Q9XDzM" target="_blank"
            class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z" />
            </svg>
          </a>
        </div>
        <!-- social - end -->
      </div>

      <div class="text-gray-400 text-sm text-center py-8">
        © 2023 AVANSO - Todos os direitos reservados.<br />CNPJ
        46.137.906/0001-53
      </div>
    </footer>
  </div>
</template>

<script>
import { userStore } from "@/stores/user";

export default {
  name: "HomePage",
  computed: {
    useUserStore() {
      return userStore.state;
    },
  },
  data() {
    return {
      anuncios: [],
      loading: true,
      totalItems: 0
    }
  },
  methods: {
  },
  mounted() {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
