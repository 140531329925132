<template>
  <form @submit.prevent="execHandler" class="flex flex-col gap-10 mt-16">
    <div class="flex flex-col gap-3 w-full">
      <label for="email" class="font-semibold">Email</label>
      <input
        type="email"
        name="email"
        :placeholder="
          this.$route.name === 'cadastro'
            ? 'e.g: name@cguro.com'
            : 'Digite seu e-mail...'
        "
        required
        v-model.trim="email"
        aria-describedby="email-helper"
        class="p-2 rounded bg-gray-800 focus:outline-none focus:ring-2 focus:ring-sky-500 hover:ring-1 hover:ring-gray-500 hover:shadow-lg"
      />
      <p
        id="email-helper"
        class="text-sm text-gray-500 dark:text-gray-400"
        v-if="this.$route.name === 'cadastro'"
      >
        *Ele será utilizado futuramente para confirmação
      </p>
    </div>

    <div class="flex flex-col gap-3 w-full">
      <label for="password" class="font-semibold">Senha</label>
      <input
        type="password"
        name="password"
        :placeholder="
          this.$route.name === 'cadastro'
            ? 'e.g: StrongPassword88.'
            : 'Digite sua senha...'
        "
        required
        v-model.trim="password"
        aria-describedby="password-helper"
        class="p-2 rounded bg-gray-800 focus:outline-none focus:ring-2 focus:ring-sky-500 hover:ring-1 hover:ring-gray-500 hover:shadow-lg"
      />
      <p
        id="password-helper"
        class="text-sm text-gray-500 dark:text-gray-400"
        v-if="this.$route.name === 'cadastro'"
      >
        *Pelo menos 8 dígitos, uma letra maíuscula e um número
      </p>
    </div>

    <template v-if="this.$route.name === 'cadastro'">
      <div class="flex flex-col gap-3 w-full">
        <label for="name" class="font-semibold">Nome completo:</label>
        <input
          type="text"
          name="name"
          placeholder="eg: João da Silva"
          required
          v-model.trim="fullName"
          class="p-2 rounded bg-gray-800 focus:outline-none focus:ring-2 focus:ring-sky-500 hover:ring-1 hover:ring-gray-500 hover:shadow-lg"
        />
      </div>
      <div class="flex flex-col gap-3 w-full">
        <label for="whatsapp" class="font-semibold">Whatsapp:</label>
        <input
          v-maska
          @maska="onMaska"
          data-maska="['+55 (##) #####-####', '+55 (##) ####-####']"
          required
          type="tel"
          placeholder="e.g: +55 (11) 98765-4321"
          class="p-2 rounded bg-gray-800 focus:outline-none focus:ring-2 focus:ring-sky-500 hover:ring-1 hover:ring-gray-500 hover:shadow-lg"
        />
      </div>
    </template>

    <router-link
      to="/"
      class="text-cyan-500 w-fit"
      v-if="this.$route.name === 'login'"
      >Esqueceu a senha?</router-link
    >
    <button
      :disabled="useManagerStore.isNotifyOpen"
      type="submit"
      class="bg-cyan-600 p-3 rounded font-bold hover:bg-cyan-500 disabled:bg-gray-500 flex justify-center items-center"
    >
      {{ action }}
    </button>
  </form>
</template>
<script>
import {
  validatePassword,
  validateFullName,
  validateWhatsapp,
} from "@/utils/validators";

import { managerStore } from "@/stores/manager";
export default {
  name: "BaseFormComponent",
  data() {
    return {
      whatsapp: "",
      fullName: "",
      email: "",
      password: "",
    };
  },
  props: {
    handleSubmit: {
      type: Function,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  methods: {
    execHandler() {
      //Cases
      switch (this.$route.name) {
        case "login":
          this.handleSubmit({ email: this.email, password: this.password });
          break;
        case "cadastro":
          if (!validatePassword(this.password)) {
            managerStore.commit("toggleNotify", {
              sucess: false,
              errorMessage: "Senha muito fraca!",
            });
            break;
          }
          if (!validateWhatsapp(this.whatsapp)) {
            managerStore.commit("toggleNotify", {
              sucess: false,
              errorMessage: "Número de celular inválido!",
            });
            break;
          }
          if (!validateFullName(this.fullName)) {
            managerStore.commit("toggleNotify", {
              sucess: false,
              errorMessage: "Nome inválido!",
            });
            break;
          }
          this.handleSubmit({
            email: this.email,
            password: this.password,
            whatsapp: this.whatsapp,
            name: this.fullName,
          });
          break;
      }
    },
    onMaska(event) {
      this.whatsapp = event.detail.unmasked;
    },
  },
  computed: {
    useManagerStore() {
      return managerStore.state;
    },
  }
};
</script>
