<template>
  <Modal v-if="modalclose" title="teste" @close="closeModal">Testeee</Modal>
  <div class="py-6 sm:py-8 lg:py-12">
    <div class="max-w-screen-xl px-4 md:px-8 mx-auto">
      <div class="grid md:grid-cols-2 gap-8" v-if="anuncios.anouncement">
        <!-- images - start -->
        <div class="grid lg:grid-cols-5 gap-4">
          <div
            class="lg:col-span-4 bg-gray-100 rounded-lg overflow-hidden relative"
          >
            <img
              :src="anuncios.anouncement.img"
              loading="lazy"
              :alt="anuncios.anouncement.name"
              class="w-full h-full object-cover object-center aspect-square max-h-96"
            />

          </div>
        </div>
        <!-- images - end -->

        <!-- content - start -->
        <div class="md:py-8">
          <!-- name - start -->
          <div class="mb-2 md:mb-3">
            <h2 class="text-gray-200 text-2xl lg:text-3xl font-bold">
              {{ anuncios.anouncement.name }}
            </h2>
          </div>
          <!-- name - end -->

          <!-- rating - start -->
          <div class="flex items-center gap-3 mb-6 md:mb-14">
            <div
              class="h-7 flex items-center bg-cyan-500 text-white rounded-full gap-1 px-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                />
              </svg>
              <span class="text-white text-sm transition duration-100"
                >56 avaliações</span
              >
            </div>
          </div>
          <!-- rating - end -->

          <!-- color - start -->
          <!-- color - end -->

          <!-- size - end -->

          <!-- price - start -->
          <div class="mb-14">
            <div class="flex items-end gap-2">
              <span class="text-gray-100 text-xl md:text-2xl font-bold">R$</span
              ><span class="text-gray-100 text-xl md:text-2xl">{{
                anuncios.anouncement.valor
              }}</span>
            </div>

            <span class="text-gray-500 text-sm"
              >Sua compra está sendo protegida pela Cguro.</span
            >
          </div>
          <!-- price - end -->

          <!-- shipping notice - start -->
          <!-- shipping notice - end -->

          <!-- buttons - start -->
          <div class="flex gap-2.5 h-16 items-center">
            <button
              class="bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-700 focus-visible:ring ring-cyan-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3 h-full"
            >
              Comprar agora
            </button>

            <button
              class="bg-gray-200 hover:bg-gray-300 focus-visible:ring ring-cyan-300 text-gray-500 active:text-gray-700 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3 h-full"
            >
              Como funciona?
            </button>
          </div>
          <!-- buttons - end -->
        </div>
        <!-- content - end -->
      </div>
    </div>

    <div class="py-6 sm:py-8 lg:py-12 mt-6 text-white">
      <div class="max-w-screen-md px-4 md:px-8 mx-auto">
        <h2
          class="text-gray-200 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-8 xl:mb-12"
        >
          Perguntas e respostas
        </h2>

        <div class="flex justify-center py-4 mb-4 font-semibold">
          <h3 class="flex text-gray-200" v-if="authenticated">
            Pergunte ao vendedor sobre o anúncio.
          </h3>
          <h3 class="text-gray-200" v-else>
            Faça
            <router-link to="/login" class="text-cyan-500 underline"
              >login</router-link
            >
            para fazer uma pergunta
          </h3>
        </div>

        <form
          v-if="authenticated"
          action="#"
          @submit.prevent="submitForm"
          class="flex justify-between h-10 w-full gap-10 my-10 items-center"
        >
          <input
            type="text"
            name="question"
            v-model="question"
            class="p-2 rounded bg-gray-800 focus:outline-none focus:ring-2 focus:ring-sky-500 hover:ring-1 hover:ring-gray-500 hover:shadow-lg w-full h-full"
          />
          <button
            type="submit"
            class="bg-cyan-500 px-4 rounded font-bold h-full disabled:bg-gray-500 flex justify-center items-center"
          >
            <Loader v-if="isLoading" class="scale-50" />
            <span v-else>PERGUNTAR</span>
          </button>
        </form>

        <div class="divide-y" v-if="anuncios.questions">
          <QuestionComponent
            v-for="question in anuncios.questions"
            :key="question"
            :question="question"
          >
          </QuestionComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionComponent from "@/components/Question.vue";
import http from "@/utils/http";
import { userStore } from "@/stores/user";
import { managerStore } from "@/stores/manager";
import Loader from "@/components/Loader.vue";

export default {
  name: "ProductPage",
  data() {
    return {
      anuncios: {},
      question: "",
      id: "",
      isLoading: false,
    };
  },

  components: {
    QuestionComponent,
    Loader,
  },

  methods: {
    submitForm: async function () {
      const user_id = userStore.getters.getId;
      const question = this.question;
      const announcement_id = this.$route.query.id;

      this.loading = true;

      await http.post("/questions", {
        user_id,
        question,
        announcement_id,
      });
      try {
        this.question = "";
        this.loading = false;
      } catch (error) {
        managerStore.commit("toggleNotify", {
          sucess: false,
          errorMessage: "Erro ao adicionar pergunta!",
        });
        this.loading = false;
      }
    },

    getProduct: async function () {
      const id = this.$route.query.id;
      const response = await http.get(`/announcement/${id}`);
      response.data.questions = response.data.questions.reverse()
      this.anuncios = response.data;
    },
  },

  computed: {
    authenticated: function () {
      return userStore.getters.getAuthenticated;
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getProduct();

    this.socket.emit("room:enter", this.$route.query.id);

    this.socket.on("announcementDelete", (anouncement) => {
      if (anouncement == this.id) {
        managerStore.commit("toggleNotify", {
          sucess: false,
          errorMessage: "Esse anúncio foi deletado!",
        });
        this.$router.replace("/market");
      }
    });

    this.socket.on("questionNew", (question) =>
      this.anuncios.questions.unshift(question)
    );
    this.socket.on("responseNew", (response) => {
      const i = this.anuncios.questions.findIndex(
        (question) => question.id == response.id
      );
      this.anuncios.questions[i] = response;
    });
    this.socket.on("anouncementUpdate", (anouncement) => {
      this.anuncios.anouncement = anouncement;
    });
  },

  beforeUnmount() {
    this.socket.emit("room:leave", this.id);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
