<template>
  <div class="bg-gray-900">
    <div class="max-w-screen-xl px-4 md:px-8 mx-auto">
      <header
        class="flex justify-between items-center py-4 mb-8 md:mb-12 xl:mb-16 text-white"
      >
        <!-- logo - start -->
        <router-link
          to="/"
          class="inline-flex items-center font-bold gap-2.5 text-2xl md:text-3xl"
          aria-label="logo"
          exact
        >
          <Logo />
        </router-link>
        <!-- logo - end -->

        <!-- buttons - start -->
        <div class="flex gap-10 items-center">
          <a
            href="https://api.whatsapp.com/send/?phone=551930941248"
            target="_blank"
            class="hidden lg:inline-block bg-gray-700 hover:bg-gray-600 focus-visible:ring ring-cyan-300 text-gray-300 font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3"
            >🛟 Suporte</a
          >
        </div>

        <button
          @click="toggleSidebar"
          type="button"
          class="inline-flex items-center lg:hidden bg-gray-700 hover:bg-gray-300 focus-visible:ring ring-cyan-300 text-gray-200 font-semibold rounded-lg gap-2 px-2.5 py-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>

          Menu
        </button>

        <transition name="mask">
          <div
            v-if="isSidebarOpen"
            class="fixed top-0 right-0 w-screen h-screen bg-gray-800 z-20 flex justify-center items-center select-none lg:hidden overflow-hidden"
          >
            <div
              class="flex flex-col w-full h-full items-start justify-center relative gap-10 text-3xl font-base ml-10"
            >
              <p
                class="font-bold absolute top-10 right-10"
                @click="toggleSidebar"
              >
                X
              </p>

              <router-link
                to="/"
                active-class="text-cyan-400"
                exact
                class="flex gap-6 items-center"
                @click="toggleSidebar"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  class="bi bi-house"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"
                  />
                </svg>
                Home
              </router-link>

              <router-link
                to="/market/todas"
                class="flex gap-6 items-center"
                active-class="text-cyan-400"
                @click="toggleSidebar"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  class="bi bi-bag-check"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                  />
                  <path
                    d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"
                  />
                </svg>
                Marketplace
              </router-link>
            </div>
          </div>
        </transition>
      </header>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";

export default {
  data: () => ({
    isSidebarOpen: false,
  }),
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
  computed: {
  },
  components: { Logo },
};
</script>
