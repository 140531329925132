<template>
  <div class="w-full h-full flex flex-wrap items-center justify-center gap-2px">
    <div class="ball w-5 h-5 rounded-full"></div>
    <div class="ball w-5 h-5 rounded-full"></div>
    <div class="ball w-5 h-5 rounded-full"></div>
</div>
</template>
<script>
export default {
  name: "LoaderComponent",
};
</script>
<style>
.ball {
  animation: bounce 1s alternate infinite;
  transition: 0.5s linear;
}

.ball {
  background: #fff;
}

.ball:nth-child(2) {
  animation-delay: 0.25s;
}

.ball:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes bounce {
  from {
    transform: scale(2);
  }

  to {
    transform: scale(1);
  }
}


</style>
