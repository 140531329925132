<template>
  <Modal v-if="closeProduct && buy" class="modal" @close="closeModal" @buyItem="buyButton(index)" :product="product">
  </Modal>
  <ModalBuy v-else-if="!buy && closeProduct" class="modal" @close="closeModal" :item="product.item_id"></ModalBuy>
  <div class="bg-gray-900 pb-6 sm:pb-8 lg:pb-12">
    <div class="max-w-screen-xl px-4 md:px-8 mx-auto">
      <div>
        <div class=" px-4 md:px-8 mx-auto">
          <nav class="border-gray-200 border-gray-600 bg-gray-900">

            <div class="mt-1 border-gray-200 mb-8 shadow-sm border-y bg-gray-800 border-gray-600">
              <div class="grid px-4 py-5 mx-auto text-sm text-gray-500 text-gray-400 md:grid-cols-3 md:px-6">
                <ul class="mb-4 space-y-4 md:mb-0" aria-labelledby="mega-menu-full-image-button">
                  <li>
                    <div @click="changeMenu('todas')"
                      :class="this.item == 'todas' ? 'text-cyan-400 cursor-pointer' : 'hover:text-cyan-400 cursor-pointer'">
                      Todas
                    </div>
                  </li>
                  <li>
                    <div @click="changeMenu('facas')"
                      :class="this.item == 'facas' ? 'text-cyan-400 cursor-pointer' : 'hover:text-cyan-400 cursor-pointer'">
                      Facas
                    </div>
                  </li>
                  <li>
                    <div @click="changeMenu('rifles')"
                      :class="this.item == 'rifles' ? 'text-cyan-400 cursor-pointer' : 'hover:text-cyan-400 cursor-pointer'">
                      Rifles
                    </div>
                  </li>
                  <li>
                    <div @click="changeMenu('snipers')" href="#"
                      :class="this.item == 'snipers' ? 'text-cyan-400 cursor-pointer' : 'hover:text-cyan-400 cursor-pointer'">
                      Snipers
                    </div>
                  </li>
                </ul>
                <ul class="mb-4 space-y-4 md:mb-0">
                  <li>
                    <div @click="changeMenu('pistols')"
                      :class="this.item == 'pistols' ? 'text-cyan-400 cursor-pointer' : 'hover:text-cyan-400 cursor-pointer'">
                      Pistols
                    </div>
                  </li>
                  <li>
                    <div @click="changeMenu('smgs')"
                      :class="this.item == 'smgs' ? 'text-cyan-400 cursor-pointer' : 'hover:text-cyan-400 cursor-pointer'">
                      SMGs
                    </div>
                  </li>
                  <li>
                    <div @click="changeMenu('machineguns')"
                      :class="this.item == 'machineguns' ? 'text-cyan-400 cursor-pointer' : 'hover:text-cyan-400 cursor-pointer'">
                      Machineguns
                    </div>
                  </li>
                </ul>
                <div
                  class="p-2 lg:py-8 text-left bg-local bg-center bg-no-repeat bg-cover rounded-lg bg-blend-multiply hover:bg-blend-soft-light"
                  >
                  <label for="search"
                    class="mb-2 text-sm font-medium text-gray-900 sr-only text-white">Search</label>
                  <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg aria-hidden="true" class="w-5 h-5 text-gray-500 text-gray-400" fill="none"
                        stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </div>
                    <input v-model="filter" @input="filtro" type="search" id="search"
                      class="block w-full p-4 pl-10 text-sm text-gray-100 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Procure aqui...">
                      <select v-model="select" @change="filtro" class="text-white absolute right-2.5 bottom-2.5 h-8 bg-gray-600 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2">
                        <option value="selecione">Selecione</option>
                        <option value="menor">Menor preço</option>
                        <option value="maior">Maior preço</option>
                      </select>
                    </div>
                </div>
              </div>
            </div>
          </nav>
          <!-- text - start -->
          <!-- text - end -->
          <div v-if="!vazio">
            <div class="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 md:gap-x-6 gap-y-8" v-if="!loading">
              <!-- product - start -->
              <ProductCard v-for="products in anuncios" :key="products" :product="products"
                @click="showProduct(products)">
              </ProductCard>
              <!-- product - end -->
            </div>
            <div v-else
              class="grid animate-pulse sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 md:gap-x-6 gap-y-8">
              <a v-for='index in 12' :key="index" href="#"
                class="group relative mb-2 block h-64 overflow-hidden rounded-lg bg-gray-800 lg:mb-3"></a>

            </div>
          </div>
          <div v-else class="w-full text-gray-100 text-lg">
            <div class="mx-auto mt-10">Nenhum item foi encontrado 🥺</div>
          </div>
        </div>
        <div v-if="anuncios.length > 0" class="flex flex-col items-center mt-20">
          <!-- Help text -->
          <span class="text-lg text-gray-700 text-gray-400">
            Página <span class="text-gray-100">{{ page }}</span> de <span class="text-gray-100">{{ totalpages }}</span>
          </span>
          <!-- Buttons -->
          <div class="inline-flex mt-2 xs:mt-0">
            <button v-if="page > 1" @click="previousItems"
              class="px-4 py-2 text-lg font-medium text-white bg-gray-800 rounded-l hover:bg-gray-700 bg-gray-800 border-gray-700 text-gray-400 hover:text-white">
              Voltar
            </button>
            <button @click="nextItems" v-if="page < totalpages"
              class="px-4 py-2 text-lg font-medium text-white bg-gray-800 border-0 border-l border-gray-700 rounded-r hover:bg-gray-700 bg-gray-800 border-gray-700 text-gray-400 hover:text-white">
              Próximo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="pt-4 ">
    <footer class="max-w-screen-2xl px-4 md:px-8 mx-auto">
      <div class="flex flex-col items-center border-t border-gray-800 pt-6">
        <!-- nav - start -->

        <div class="flex gap-4">
          <a href="https://discord.gg/pfa4Q9XDzM" target="_blank"
            class="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
              <path
                d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z" />
            </svg>
          </a>
        </div>
        <!-- social - end -->
      </div>

      <div class="text-gray-400 text-sm text-center py-8">
        © 2023 AVANSO - Todos os direitos reservados.<br />CNPJ
        46.137.906/0001-53
      </div>
    </footer>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import Modal from "@/components/Modal.vue"
import ModalBuy from "@/components/ModalBuy.vue"

export default {
  name: "MarketplacePage",
  data() {
    return {
      anuncios: [],
      closeProduct: false,
      product: null,
      page: null,
      buy: true,
      totalpages: null,
      loading: true,
      item: this.$route.params.item,
      vazio: false,
      filter: null,
      select: "selecione"
    };
  },

  components: {
    ProductCard, Modal, ModalBuy
  },

  methods: {
    findAllAnuncios: async function () {
      this.loading = true
      this.socket.emit('getItems', { page: this.page, item: this.item, view: 12, filter: this.filter, select: this.select }, response => {
        this.totalpages = response.items.totalPages

        this.$router.push({ query: { ...this.$route.query, page: response.items.page } })

        this.anuncios = response.items.docs

        if (response.items.docs.length == 0) {
          this.vazio = true
        }else{
          this.vazio = false
        }

        this.loading = false
      })
    },

    showProduct(product) {
      this.product = product
      this.closeProduct = true
    },

    closeModal() {
      this.closeProduct = false
      this.product = null
      this.buy = true
    },

    nextItems() {
      this.page = Number(this.page) + 1
      this.$router.push({ query: { ...this.$route.query, page: this.page } })
      this.findAllAnuncios();
    },

    previousItems() {
      this.page = Number(this.page) - 1
      this.$router.push({ query: { ...this.$route.query, page: this.page } })
      this.findAllAnuncios();
    },

    changeMenu(data) {
      this.filter = null
      this.select = "selecione"
      this.vazio = false
      this.$router.push({ path: '/market/' + data, query: { ...this.$route.query, page: 1 } })
      this.item = data
      this.page = 1
      this.findAllAnuncios()
    },

    buyButton(index) {
      this.buy = false
      console.log(index)
    },

    filtro(){
      console.log(this.select)
      this.findAllAnuncios();
    }
  },

  mounted() {
    if (!this.$route.query.page) {
      this.$router.push({ query: { ...this.$route.query, page: 1 } })
      this.page = 1
    } else {
      this.$router.push({ query: { ...this.$route.query, page: this.$route.query.page } })
      this.page = this.$route.query.page
    }
    this.findAllAnuncios();
  },
};
</script>

<style>
.modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
}</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
