import { createStore } from "vuex";

const managerStore = createStore({
  state() {
    return {
      sucess: true,
      errorMessage: "Sucesso ao logar!",
      isNotifyOpen: false
    };
  },
  mutations: {
    toggleNotify(state, payload) {
      state.isNotifyOpen = true
      state.sucess = payload.sucess
      state.errorMessage = payload.errorMessage
      setTimeout(() => state.isNotifyOpen = false, 2000)
    }
  },
});

export { managerStore };
