<template>
  <div>
    <a href="#" class="group relative mb-2 block w-full h-64 overflow-hidden rounded-lg bg-gray-800 lg:mb-3">
      <div class="mt-2" v-if="data">
        <span v-if="data == true"
          class="bg-green-600 text-white text-xs font-extrabold m-2 px-2 py-1 rounded text-bold">Disponível</span>
        <span v-else class="bg-gray-700 text-white text-xs font-extrabold m-2 px-2 py-1 rounded text-bold">{{ data
        }}</span>
        <span v-if="product.item.best_offer"
          class="bg-red-600 items-end text-red-100 text-xs font-extrabold px-2 py-1 rounded text-bold">🔥 OFERTA!</span>
      </div>
      <div class="mt-2 w-32 animate-pulse" v-else>
        <span class="bg-gray-700 text-gray-500 text-xs font-extrabold m-2 px-2 py-1 w-32 rounded text-bold">Carregando...</span>
      </div>
      <img :src="product.item.image" class="transition duration-200 group-hover:scale-110 h-64 mx-auto" />
      <div v-if="product.item.stickers" class="grid mt-48 gap-1 m-2 grid-cols-4">
        <!-- product - start -->
        <div v-for="sticker in product.item.stickers" :key="sticker">
          <div class="group relative flex h-12 w-12 items-end overflow-hidden rounded-lg p-4 shadow-lg">
            <img :src="sticker.url" :alt="sticker.name"
              class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-105" />

          </div>
        </div>
        <!-- product - end -->
      </div>
    </a>
    <div>
      <div v-if="product.item.float_value && product.item.float_value > 0" class="mb-3">
        <div class="mt-2 text-white text-sm" :style="'margin-left: ' + product.item.float_value * 100 + '%;'">
          {{
            product.item.float_value }}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
        <div class="w-full h-1"
          style="background-image: linear-gradient(to right, #72f04f, #a2f04f,#fce728, #fce728, #f5253d);"></div>
      </div>
      <div href="#" class="hover:gray-800 mb-1 text-gray-300 h-12 transition duration-100">
        {{ product.item.market_hash_name }}</div>

      <div class="flex mt-5 gap-2">
        <span class="font-bold text-lg text-gray-200 lg:text-xl">{{ product.item.price.toLocaleString("pt-BR", {
          minimumFractionDigits: 2, style: 'currency', currency: 'BRL'
        }) }}</span>
        <span class="text-red-500"><a target="_blank"
            :href="'https://steamcommunity.com/market/listings/730/' + product.item.market_hash_name"
            class="bg-gray-600 text-white hover:bg-gray-700 focus:ring-4 focus:ring-blue-300 font-medium rounded text-xs px-2 py-2 mb-2 focus:outline-none">Ver
            na Steam</a></span>
      </div>
    </div>
  </div>
  <!-- product - end -->
</template>

<script>
import moment from 'moment';

export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null
    }
  },
  methods: {
    dateTime() {

      const timestamp = this.product.item.withdrawable_at;
      const dataFutura = moment.unix(timestamp);

      const agora = moment();
      const diff = moment.duration(dataFutura.diff(agora));

      var textodia = diff.days() == 1 ? " dia " : " dias "

      this.data = diff.days() > 0 ? diff.days() + textodia + diff.hours() + "h " + diff.minutes() + "m " + diff.seconds() + "s " : true
    }
  },

  mounted() {
    this.dateTime
    setInterval(this.dateTime, 1000)
  }
};
</script>
