import { createRouter, createWebHistory } from "vue-router";
import { requireAuth } from "./auth";
import HomePage from "@/pages/Home.vue";
import MarketPage from "@/pages/Market.vue";
import ProductPage from "@/pages/Product.vue";
import LoginPage from "@/pages/Login.vue";
import RegisterPage from "@/pages/Register.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/market/:item",
    name: "market",
    component: MarketPage
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/cadastro",
    name: "cadastro",
    component: RegisterPage,
  },
  {
    path: "/produto",
    name: "product",
    component: ProductPage,
    beforeEnter: requireAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
