import { createStore } from "vuex";

const userStore = createStore({
  state() {
    return {
      id: "",
      name: "",
      token: "",
      authenticated: false,
    };
  },

  getters: {
    getName(state) {
      return state.name;
    },

    getId(state) {
      return state.id;
    },

    getToken(state) {
      return state.token;
    },

    getAuthenticated(state) {
      return state.authenticated;
    },
  },

  mutations: {
    login(state, payload) {
      state.id = payload.id;
      state.name = payload.name;
      state.token = payload.token;
      state.authenticated = true;
    },
    reset(state) {
      state.id = "";
      state.name = "";
      state.token = "";
      state.authenticated = false;
    },
	initialiseStore(state) {
		// Check if the ID exists
		if(localStorage.getItem('userStore')) {
			// Replace the state object with the stored item
			this.replaceState(
				Object.assign(state, JSON.parse(localStorage.getItem('userStore')))
			);
		}
	}
  },
});

export { userStore };
