import http from "../utils/http";
import { userStore } from "@/stores/user";

export function isAuthenticated() {
  const token = userStore.state.token;
  if (token) {
    return http
      .get("/auth", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => true)
      .catch(() => {
        userStore.commit("reset");
        return false;
      });
  } else {
    return Promise.resolve(false);
  }
}

// Navigation Guard para rotas que precisam de login
export function requireAuth(to, from, next) {
  isAuthenticated().then((authenticated) => {
    if (authenticated) {
      next();
    } else if(to.name === "product"){
      next();
    }else{
      next({ name: "login" });
    }
  });
}
