<template>
  <TheHeader v-if="this.$route.path !== '/login' && this.$route.path !== '/cadastro'" />
  <router-view />
  <Notify />
</template>

<script>
import Notify from "./components/Notify.vue";
import TheHeader from "./layouts/TheHeader.vue";
export default {
  components: { TheHeader, Notify },
};
</script>

<style>
@import url("./assets/css/transitions.css");
@import url("./assets/css/main.css");
</style>
