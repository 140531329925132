import { createApp } from 'vue'

import App from './App.vue'
import { io } from "socket.io-client";
import router from './router'
import {vMaska} from 'maska'
import { userStore } from './stores/user';
import axios from './utils/http/index'
import VueQrcode from '@chenfengyuan/vue-qrcode';

userStore.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('userStore', JSON.stringify(state));
});

const socket = io('https://cguro.tomada.io');

var aplicativo = createApp(App).use(router).directive("maska", vMaska)

aplicativo.component(VueQrcode.name, VueQrcode);
aplicativo.config.globalProperties.socket = socket
aplicativo.config.globalProperties.axios = axios
aplicativo.mixin({
    created() {
      userStore.commit("initialiseStore")// Chamando a função global
    }
  })
aplicativo.mount('#app')
